<template>
  <div>
    <input type="file" id="inputFile" ref="file" accept="application/pdf" @change="onChange"/>
    <p style="font-size: 0.75rem;margin-top: 0.313rem;margin-bottom: 0.313rem;">{{ type.name }}</p>
    <custom-button type="secondary" @click="add">Добавить</custom-button>
  </div>
</template>
<script>
import CustomButton from '@/basic/Button.vue'

export default {
  name: 'DocumentInput',
  components: { CustomButton },
  props: {
    type: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      file: ''
    }
  },
  methods: {
    add() {
      this.$refs.file.click();
    },
    onChange() {
      this.file = this.$refs.file.files[0];
      this.$emit('input', this.file, this.type.id)
      this.file = ''
    }
  }
}
</script>

<style scoped lang="scss">
input[type="file"]{
  position: absolute;
  top: -500px;
}
</style>
