import { render, staticRenderFns } from "./DocumentInput.vue?vue&type=template&id=3d433dcc&scoped=true"
import script from "./DocumentInput.vue?vue&type=script&lang=js"
export * from "./DocumentInput.vue?vue&type=script&lang=js"
import style0 from "./DocumentInput.vue?vue&type=style&index=0&id=3d433dcc&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d433dcc",
  null
  
)

export default component.exports