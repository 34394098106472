<template>
  <div v-if="loading" :class="$style.teacherDetails__loading">
    <Loading />
  </div>

  <div v-else :class="$style.teacherDetails">
    <div style="display: flex; align-items: center; justify-content: space-between">
      <Title text="Детали преподавателя" position="left" />
      <div :class="$style.title" v-if="teacherInfo?.rating?.rating">
        <img src="@/assets/icons/star.svg" alt="star" width="16" height="16" />
        <p>{{ teacherInfo?.rating?.rating }}</p>
      </div>
    </div>
    <Tabs
      style="margin-bottom: 1.5rem"
      :tabs="tabs"
      :default-active-tab-id="activeTab"
      @input="(id) => (activeTab = id)"
    />

    <div
      v-if="Object.keys(this.teacherDetails).length"
      :class="$style.teacherDetails__content"
    >
      <div v-if="activeTab === 1" :class="$style.teacherDetails__content_wrap">
        <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
          <p>Фамилия*</p>
          <div>
            <span>{{ setValueInField(teacherAttributes.lastName) }}</span>
          </div>
        </div>
        <div v-if="changeInfo" :class="$style.teacherDetails__content_input">
          <p :class="$style.teacherDetails__content_text">Фамилия*</p>
          <Input
            v-model="teacher.lastName"
            placeholder="Не заполнено"
            @input="setText('lastName')"
          />
        </div>

        <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
          <p>Имя*</p>
          <div>
            <span>{{ setValueInField(teacherAttributes.firstName) }}</span>
          </div>
        </div>
        <div v-if="changeInfo" :class="$style.teacherDetails__content_input">
          <p :class="$style.teacherDetails__content_text">Имя*</p>
          <Input
            v-model="teacher.firstName"
            placeholder="Не заполнено"
            @input="setText('firstName')"
          />
        </div>

        <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
          <p>Отчество</p>
          <div>
            <span>{{ setValueInField(teacherAttributes.middleName) }}</span>
          </div>
        </div>
        <div v-if="changeInfo" :class="$style.teacherDetails__content_input">
          <p :class="$style.teacherDetails__content_text">Отчество</p>
          <Input v-model="teacher.middleName" placeholder="Не заполнено" />
        </div>

        <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
          <p>Год рождения</p>
          <div>
            <span>{{ setValueInField(teacherAttributes.birthday) }}</span>
          </div>
        </div>
        <div v-if="changeInfo" :class="$style.teacherDetails__content_input">
          <p :class="$style.teacherDetails__content_text">Год рождения</p>
          <date-picker
            v-model="teacherBirthday"
            :class="$style.teacherDetails__input"
            :lang="lang"
            type="date"
            :editable="false"
            placeholder="Выберите дату"
            :formatter="momentFormat"
          />
        </div>

        <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
          <p>Пол</p>
          <div>
            <span>{{ setValueInField(teacherAttributes?.gender?.name) }}</span>
          </div>
        </div>
        <div v-if="changeInfo" :class="$style.teacherDetails__content_input">
          <p :class="$style.teacherDetails__content_text">Пол</p>
          <DefaultSelect
            v-model="teacher.genderId"
            style="width: 10rem"
            placeholder="Не заполнено"
            :items="init.genders"
            @input="(val) => (teacher.genderId = val?.id ?? val)"
          />
        </div>

        <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
          <p>Регион*</p>
          <div>
            <span>{{ setValueInField(teacherAttributes.region) }}</span>
          </div>
        </div>
        <div v-if="changeInfo" :class="$style.teacherDetails__content_input">
          <p :class="$style.teacherDetails__content_text">Регион*</p>
          <div :class="$style.parentDetails__wrapper">
            <Input
              v-model="locality.region"
              :class="$style.teacherDetails__address"
              type="secondary"
              placeholder="Выберите из списка*"
              :autocomplete="false"
              :error="errors.region && !firstFill"
              @input="getRegions(locality.region)"
              @enter="setRegion(locality.region)"
            />
            <transition name="select">
              <div v-if="regions.length > 0" :class="$style.teacherDetails__dropdown">
                <p
                  v-for="(r, i) in regions"
                  :key="i"
                  :class="$style.teacherDetails__region"
                  @click="setRegion(r.region)"
                >
                  {{ r.region }}
                </p>
              </div>
            </transition>

            <div
              v-if="regions.length > 0"
              :class="$style.teacherDetails__close"
              @click="resetRegions"
            />
          </div>
        </div>

        <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
          <p>Телефон</p>
          <div>
            <span>{{ setValueInField(teacherDetails.phone) }}</span>
          </div>
        </div>
        <div v-if="changeInfo" :class="$style.teacherDetails__content_input">
          <p :class="$style.teacherDetails__content_text">Телефон</p>
          <Input
            :class="$style.teacherDetails__input"
            v-model="teacher.phone"
            :readonly="true"
            :mask="'+7 (###) ###-##-##'"
            placeholder="+7 (___) ___-__-__"
          />
        </div>

        <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
          <p>Дополнительный телефон</p>
          <div>
            <span>{{ setValueInField(teacherDetails.reservedPhone) }}</span>
          </div>
        </div>
        <div v-if="changeInfo" :class="$style.teacherDetails__content_input">
          <p :class="$style.teacherDetails__content_text">Дополнительный телефон</p>
          <Input
            :class="$style.teacherDetails__input"
            v-model="teacherReservedPhone"
            :mask="'7 (###) ###-##-##'"
            :readonly="true"
            placeholder="7 (___) ___-__-__"
          />
        </div>

        <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
          <p>Почта*</p>
          <div>
            <span>{{ setValueInField(teacherDetails.email) }}</span>
          </div>
        </div>
        <div v-if="changeInfo" :class="$style.teacherDetails__content_input">
          <p :class="$style.teacherDetails__content_text">Почта*</p>
          <Input
            v-model="teacher.email"
            :class="$style.teacherDetails__input"
            placeholder="________@____.__*"
            :error="errors.email && !firstFill"
            @input="setEmail"
          />
        </div>

        <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
          <p>Статус</p>
          <div>
            <span>{{ isVerified ? 'Активен' : 'Не активен' }}</span>
          </div>
        </div>
        <div v-if="changeInfo" :class="$style.teacherDetails__content_input">
          <p :class="$style.teacherDetails__content_text">Статус</p>
          <Input v-model="status" :class="$style.teacherDetails__input" :readonly="true" />
        </div>
      </div>

      <div v-if="activeTab === 1" :class="$style.teacherDetails__content_wrap">
        <div :class="{ [$style.border__education]: changeInfo }">
          <div
            v-for="(education, index) in teacherEducations"
            :key="education.id"
            :class="$style.teacherDetails__content_education"
          >
            <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
              <p :style="{ color: education.manualFilling ? 'red' : '#7f7f7f' }">
                Вуз* {{ education.manualFilling ? '(заполнен вручную)' : '' }}
              </p>
              <div>
                <input
                  v-if="education.manualFilling"
                  v-model="searchText"
                  :placeholder="education.university"
                  :readonly="editEducation !== education.id"
                  :title="education.university"
                />
                <template v-else>
                  {{ setValueInField(education.university) }}
                </template>
                <template v-if="education.manualFilling">
                  <img
                    v-if="editEducation !== education.id"
                    src="@/assets/icons/edit.svg"
                    alt="Редактирование"
                    title="Редактирование"
                    @click="editOneEducation(education.id)"
                  />
                  <img
                    v-if="editEducation === education.id"
                    src="@/assets/icons/close-black.svg"
                    alt="Отмена"
                    title="Отмена"
                    @click="editOneEducation(null)"
                  />
                  <img
                    src="@/assets/icons/save.svg"
                    alt="Сохранить"
                    title="Сохранить"
                    @click="saveEducation"
                  />
                  <input
                    v-if="editEducation === education.id"
                    v-model="manualFilling"
                    :class="$style.teacherDetails__checkbox"
                    type="checkbox"
                    title="Заполнить вручную"
                    checked="checked"
                  />
                </template>
              </div>
              <div
                v-if="educations.length && editEducation === education.id"
                :class="$style.teacherDetails__content_info_educations"
              >
                <div
                  v-for="({ address, name }, i) of educations"
                  :key="i"
                  @click="selectEducation({ address, name, educationId: education.id })"
                >
                  {{ name }} {{ address }}
                </div>
              </div>
            </div>
            <div v-if="changeInfo" :class="$style.teacherDetails__content_input">
              <p :class="$style.teacherDetails__content_text">Вуз*</p>
              <Input
                v-model="education.university"
                :class="$style.teacherDetails__address"
                type="secondary"
                placeholder="Выберите из списка*"
                :autocomplete="false"
                @input="getEducationUniversity(education.university, index)"
                @enter="setEducationUniversity(education.university, index)"
              />
              <transition name="select">
                <div
                  v-if="educationUniversity.length > 0"
                  :class="$style.teacherDetails__dropdown"
                >
                  <p
                    v-for="(r, i) in educationUniversity"
                    :key="i"
                    :class="$style.teacherDetails__region"
                    @click="setEducationUniversity(r.name, index)"
                  >
                    {{ r.name }}
                  </p>
                </div>
              </transition>

              <div
                v-if="educationUniversity.length > 0"
                :class="$style.teacherDetails__close"
                @click="resetEducationUniversity"
              />
            </div>

            <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
              <p>Город*</p>
              <div>
                {{ setValueInField(education.place) }}
              </div>
            </div>
            <div v-if="changeInfo" :class="$style.teacherDetails__content_input">
              <p :class="$style.teacherDetails__content_text">Город*</p>
              <Input
                v-model="education.place"
                :class="$style.teacherDetails__address"
                type="secondary"
                placeholder="Выберите из списка*"
                :autocomplete="false"
                :error="errors.region && !firstFill"
                @input="getEducationRegions(education.place, index)"
                @enter="setEducationRegion(education.place, index)"
              />
              <transition name="select">
                <div
                  v-if="educationRegions.length > 0"
                  :class="$style.teacherDetails__dropdown"
                >
                  <p
                    v-for="(r, i) in educationRegions"
                    :key="i"
                    :class="$style.teacherDetails__region"
                    @click="setEducationRegion(r.region, index)"
                  >
                    {{ r.region }}
                  </p>
                </div>
              </transition>

              <div
                v-if="educationRegions.length > 0"
                :class="$style.teacherDetails__close"
                @click="resetEducationRegions"
              />
            </div>

            <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
              <p>Специальность*</p>
              <div>
                {{ setValueInField(education.speciality) }}
              </div>
            </div>
            <div v-if="changeInfo" :class="$style.teacherDetails__content_input">
              <p :class="$style.teacherDetails__content_text">Специальность*</p>
              <Input v-model="education.speciality" :class="$style.teacherDetails__input" />
            </div>

            <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
              <p>Виды высшего образования</p>
              <div>
                {{ setValueInField(education.type ? education.type.name : '') }}
              </div>
            </div>
            <div v-if="changeInfo" :class="$style.teacherDetails__content_input">
              <p :class="$style.teacherDetails__content_text">Виды высшего образования</p>
              <DefaultSelect
                v-model="education.typeId"
                style="width: 10rem"
                placeholder="Не заполнено"
                :items="init.educationsTypes"
                @input="(val) => (education.typeId = val?.id ?? val)"
              />
            </div>

            <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
              <p>Педагогическое образование*</p>
              <div>
                {{ education.hasAdvancedTraining === 1 ? 'Есть' : 'Нет' }}
              </div>
            </div>
            <div v-if="changeInfo" :class="$style.teacherDetails__content_input">
              <p :class="$style.teacherDetails__content_text">Педагогическое образование*</p>
              <DefaultSelect
                v-model="education.hasAdvancedTraining"
                style="width: 10rem"
                placeholder="Не заполнено"
                :items="hasAdvancedTraining"
                @input="(val) => (education.hasAdvancedTraining = val?.id ?? val)"
              />
            </div>

            <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
              <p>Год окончания*</p>
              <div>
                {{ setValueInField(education.year) }}
              </div>
            </div>
            <div v-if="changeInfo" :class="$style.teacherDetails__content_input">
              <p :class="$style.teacherDetails__content_text">Год окончания*</p>
              <InputNumber
                v-model="education.year"
                :class="$style.teacherDetails__input"
                type="container"
                :maxlength="4"
                :editable="false"
                placeholder="Выберите дату"
              />
            </div>
          </div>
          <Button
            v-if="changeInfo"
            style="
              position: relative;
              left: 85%;
              width: 12.5rem;
              height: 2rem;
              margin: 0 6.25rem 1.063rem 0;
            "
            type="table"
            @click="addEducation"
            >Добавить</Button
          >
          <Button
            v-if="changeInfo"
            style="
              position: relative;
              left: 85%;
              width: 12.5rem;
              height: 2rem;
              margin: 0 6.25rem 1.063rem 0;
            "
            type="table"
            @click="deleteEducation"
            >Удалить</Button
          >
        </div>
        <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
          <p>Преподавательский стаж*</p>
          <div>{{ setPluralize(teacherInfo.teachingServiceYears) }}</div>
        </div>
        <div v-if="changeInfo" :class="$style.teacherDetails__content_input">
          <p :class="$style.teacherDetails__content_text">Преподавательский стаж*</p>
          <InputNumber
            v-model="teacher.teachingServiceYears"
            :class="$style.teacherDetails__input"
            type="container"
            :maxlength="2"
            placeholder="Лет"
          />
        </div>

        <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
          <p>Стаж научной деятельности*</p>
          <div>{{ setPluralize(teacherInfo.scientificServiceYears) }}</div>
        </div>
        <div v-if="changeInfo" :class="$style.teacherDetails__content_input">
          <p :class="$style.teacherDetails__content_text">Стаж научной деятельности*</p>
          <InputNumber
            v-model="teacher.scientificServiceYears"
            :class="$style.teacherDetails__input"
            type="container"
            :maxlength="2"
            placeholder="Лет"
          />
        </div>

        <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
          <p>Желаемый класс учеников*</p>
          <div>
            c {{ teacherInfo.minAcceptableGrade }} по
            {{ teacherInfo.maxAcceptableGrade }} класс
          </div>
        </div>
        <div v-if="changeInfo" :class="$style.teacherDetails__content_input">
          <p :class="$style.teacherDetails__content_text">Желаемый класс учеников*</p>
          <div
            style="
              display: flex;
              align-items: center;
              width: 230px;
              justify-content: space-between;
            "
          >
            <InputNumber
              style="width: 100px"
              v-model="teacher.minAcceptableGrade"
              :class="$style.teacherDetails__input"
              type="container"
              :maxlength="2"
              placeholder="с*"
            />
            <span>-</span>
            <InputNumber
              style="width: 100px"
              v-model="teacher.maxAcceptableGrade"
              :class="$style.teacherDetails__input"
              type="container"
              :maxlength="2"
              placeholder="до*"
            />
          </div>
        </div>

        <div
          v-if="teacherInfo.teachingTypes.length"
          :class="$style.teacherDetails__content_info"
        >
          <p>Тип подготовки</p>
          <div v-for="type in teacherInfo.teachingTypes" :key="type.teachingType.id">
            {{ `${type.teachingType.name} ` }}
          </div>
        </div>

        <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
          <p>Ученая степень</p>
          <div>
            {{
              setValueInField(
                teacherInfo?.academicDegree?.academicDegreeId
                  ? teacherInfo?.academicDegree?.academicDegree?.name
                  : null
              )
            }}
          </div>
        </div>
        <div v-if="changeInfo" :class="$style.teacherDetails__content_input">
          <p :class="$style.teacherDetails__content_text">Ученая степень</p>
          <DefaultSelect
            v-model="teacher.academicDegreeId"
            style="width: 10rem"
            placeholder="Не заполнено"
            :items="init.academicDegrees"
            @input="(val) => (teacher.academicDegreeId = val?.id ?? val)"
          />
        </div>

        <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
          <p>Предметы*</p>
          <div>
            <span
              v-for="subject in teacherInfo.subjects"
              :key="subject.id"
              :class="$style.teacherDetails__content_item"
            >
              {{ `${subject.subject.name} ` }}
            </span>
          </div>
        </div>
        <div v-if="changeInfo" :class="$style.teacherDetails__content_input">
          <p :class="$style.teacherDetails__content_text">Предметы*</p>
          <multiselect
            v-model="subjects"
            :options="constants.subjects"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            placeholder="Выберите предметы"
            select-label=""
            :hide-selected="true"
            track-by="name"
            label="name"
          />
        </div>

        <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
          <p>Тип занятий*</p>
          <div>
            <span
              v-for="type in teacherInfo.lessonsTypes"
              :key="type.id"
              :class="$style.teacherDetails__content_item"
              >{{ `${type.lessonType.name} ` }}</span
            >
          </div>
        </div>
        <div v-if="changeInfo" :class="$style.teacherDetails__content_input">
          <p :class="$style.teacherDetails__content_text">Тип занятий*</p>
          <multiselect
            v-model="types"
            :options="constants.lessonsTypes"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            placeholder="Выберите тип"
            select-label=""
            :hide-selected="true"
            track-by="name"
            label="name"
          />
        </div>
      </div>

      <div
        v-if="activeTab === 2"
        :class="$style.teacherDetails__calendar"
      >
        <div :class="$style.periods">
          <img src="../assets/icons/calendar-pin.svg" alt=" " class="pin" />

          <p :class="$style.subtitle">ГРАФИК РАБОТЫ</p>

          <div :class="$style.calendar">
            <Calendar
              :periods="periods"
              @set-date="(date) => setChoosenDate(date)"
            />
          </div>
        </div>

        <periods-list
          :choosen-date="choosenDate"
          :periods="periods"
          :all-orders="teacherOrders.rows"
        />
      </div>

      <div v-if="activeTab === 3" :class="$style.teacherDetails__content_wrap">
        <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
          <p>Текущее место работы</p>
          <div>
            {{ setValueInField(teacherInfo.currentWorkingPlace) }}
          </div>
        </div>
        <div v-if="changeInfo" :class="$style.teacherDetails__content_input">
          <p :class="$style.teacherDetails__content_text">Текущее место работы</p>
          <Input v-model="teacher.currentWorkingPlace" :class="$style.teacherDetails__input" />
        </div>

        <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
          <p>Количество успешно сдавших экзамены</p>
          <div>
            {{ setValueInField(teacherInfo.successfulStudentsQuantity) }}
          </div>
        </div>
        <div v-if="changeInfo" :class="$style.teacherDetails__content_input">
          <p :class="$style.teacherDetails__content_text">
            Количество успешно сдавших экзамены
          </p>
          <InputNumber
            v-model="teacher.successfulStudentsQuantity"
            type="container"
            :class="$style.teacherDetails__input"
            :maxlength="10"
          />
        </div>

        <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
          <p>Количество стобальников</p>
          <div>
            {{ setValueInField(teacherInfo.perfectStudentsQuantity) }}
          </div>
        </div>
        <div v-if="changeInfo" :class="$style.teacherDetails__content_input">
          <p :class="$style.teacherDetails__content_text">Количество стобальников</p>
          <InputNumber
            type="container"
            v-model="teacher.perfectStudentsQuantity"
            :class="$style.teacherDetails__input"
            :maxlength="10"
          />
        </div>

        <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
          <p>Олимпиадники</p>
          <div v-if="teacherInfo.olympiadsWinners.length">
            <span
              v-for="olympiadsWinner in teacherInfo.olympiadsWinners"
              :key="olympiadsWinner.id"
              :class="$style.teacherDetails__content_item"
              >{{ `${olympiadsWinner.text} - ${olympiadsWinner.place} место ` }}</span
            >
          </div>
          <div v-else>
            <span>Не заполнено</span>
          </div>
        </div>

        <div
          v-if="changeInfo"
          :class="[
            $style.teacherDetails__content_input,
            $style.teacherDetails__content_input_border
          ]"
        >
          <p :class="$style.teacherDetails__content_text">Олимпиадники</p>
          <div
            v-for="olympiadsWinner in olympiadsWinners"
            :key="olympiadsWinner.id"
            :class="$style.teacherDetails__content_input"
            style="display: flex; justify-content: space-between"
          >
            <div :class="$style.teacherDetails__content_input">
              <p :class="$style.teacherDetails__content_text">Название олимпиады</p>
              <Input v-model="olympiadsWinner.text" />
            </div>
            <div :class="$style.teacherDetails__content_input">
              <p :class="$style.teacherDetails__content_text">Этап олимпиады</p>
              <DefaultSelect
                v-model="olympiadsWinner.typeId"
                :items="init.olympiadsTypes"
                style="width: 10rem"
                placeholder="Не заполнено"
                @input="(val) => (olympiadsWinner.typeId = val?.id ?? val)"
              />
            </div>
            <div :class="$style.teacherDetails__content_input">
              <p :class="$style.teacherDetails__content_text">Место</p>
              <InputNumber v-model="olympiadsWinner.place" :maxlength="2" type="container" />
            </div>
          </div>
          <Button type="table" @click="addElement('olympiadsWinners')">Добавить</Button>
        </div>

        <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
          <p>Участие в конкурсах</p>
          <div v-if="teacherInfo.competitions.length">
            <span
              v-for="competition in teacherInfo.competitions"
              :key="competition.id"
              :class="$style.teacherDetails__content_item"
              >{{ `${competition.text} ` }}</span
            >
          </div>
          <div v-else>
            <span>Не заполнено</span>
          </div>
        </div>

        <div
          v-if="changeInfo"
          :class="[
            $style.teacherDetails__content_input,
            $style.teacherDetails__content_input_border
          ]"
        >
          <p :class="$style.teacherDetails__content_text">Участие в конкурсах</p>
          <div
            v-for="competition in competitions"
            :key="competition.id"
            style="display: flex; justify-content: space-between"
            :class="$style.teacherDetails__content_input"
          >
            <div :class="$style.teacherDetails__content_input">
              <p :class="$style.teacherDetails__content_text">Название конкурса</p>
              <Input v-model="competition.text" :class="$style.teacherDetails__input" />
            </div>
            <div :class="$style.teacherDetails__content_input">
              <p :class="$style.teacherDetails__content_text">Тип конкурса</p>
              <DefaultSelect
                v-model="competition.typeId"
                style="width: 10rem"
                placeholder="Не заполнено"
                :items="init.competitionsTypes"
                @input="(val) => (competition.typeId = val?.id ?? val)"
              />
            </div>
            <div :class="$style.teacherDetails__content_input">
              <p :class="$style.teacherDetails__content_text">Документ</p>
              <DefaultSelect
                v-model="competition.documentId"
                style="width: 10rem"
                placeholder="Не заполнено"
                :items="pedagogFiles"
                @input="(val) => (competition.documentId = val?.id ?? val)"
              />
            </div>
          </div>
          <Button type="table" @click="addElement('competitions')">Добавить</Button>
        </div>

        <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
          <p>Публикации и статьи</p>
          <div v-if="teacherInfo.publications.length">
            <span
              v-for="publication in teacherInfo.publications"
              :key="publication.id"
              :class="[
                $style.teacherDetails__content_item,
                $style.teacherDetails__content_flex
              ]"
            >
              <span>Заголовок: {{ publication.heading }}</span>
              <span>Название: {{ publication.name }}</span>
              <span>Ссылка: {{ publication.link }}</span>
              <span>Год участия: {{ publication.year }}</span>
            </span>
          </div>
          <div v-else>
            <span>Не заполнено</span>
          </div>
        </div>

        <div
          v-if="changeInfo"
          :class="[
            $style.teacherDetails__content_input,
            $style.teacherDetails__content_input_border
          ]"
        >
          <p :class="$style.teacherDetails__content_text">Публикации и статьи</p>
          <div
            v-for="publication in publications"
            :key="publication.id"
            style="display: flex; justify-content: space-between"
          >
            <div :class="$style.teacherDetails__content_input">
              <p :class="$style.teacherDetails__content_text">Заголовок</p>
              <Input v-model="publication.heading" />
            </div>
            <div :class="$style.teacherDetails__content_input">
              <p :class="$style.teacherDetails__content_text">Название</p>
              <Input v-model="publication.name" />
            </div>
            <div :class="$style.teacherDetails__content_input">
              <p :class="$style.teacherDetails__content_text">Ссылка</p>
              <Input v-model="publication.link" />
            </div>
            <div :class="$style.teacherDetails__content_input">
              <p :class="$style.teacherDetails__content_text">Год участия</p>
              <InputNumber type="container" v-model="publication.year" :maxlength="4" />
            </div>
          </div>
          <Button type="table" @click="addElement('publications')">Добавить</Button>
        </div>

        <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
          <p>Участие в конференциях</p>
          <div v-if="teacherInfo.conferences.length">
            <span
              v-for="conference in teacherInfo.conferences"
              :key="conference.id"
              :class="[
                $style.teacherDetails__content_item,
                $style.teacherDetails__content_flex
              ]"
            >
              <span>Название: {{ conference.name }}</span>
              <span>Место: {{ conference.place }}</span>
              <span>Год участия: {{ conference.year }}</span>
            </span>
          </div>
          <div v-else>
            <span>Не заполнено</span>
          </div>
        </div>

        <div
          v-if="changeInfo"
          :class="[
            $style.teacherDetails__content_input,
            $style.teacherDetails__content_input_border
          ]"
        >
          <p :class="$style.teacherDetails__content_text">Участие в конференциях</p>
          <div
            v-for="conference in conferences"
            :key="conference.id"
            style="display: flex; justify-content: space-between"
          >
            <div :class="$style.teacherDetails__content_input">
              <p :class="$style.teacherDetails__content_text">Название</p>
              <Input v-model="conference.name" />
            </div>
            <div :class="$style.teacherDetails__content_input">
              <p :class="$style.teacherDetails__content_text">Место</p>
              <Input v-model="conference.place" />
            </div>
            <div :class="$style.teacherDetails__content_input">
              <p :class="$style.teacherDetails__content_text">Год участия</p>
              <InputNumber type="container" v-model="conference.year" :maxlength="4" />
            </div>
          </div>
          <Button type="table" @click="addElement('conferences')">Добавить</Button>
        </div>

        <div v-if="!changeInfo" :class="$style.teacherDetails__content_info">
          <p>Участие в образовательных проектах</p>
          <div v-if="teacherInfo.educationalProjects.length">
            <span
              v-for="educationalProject in teacherInfo.educationalProjects"
              :key="educationalProject.id"
              :class="[
                $style.teacherDetails__content_item,
                $style.teacherDetails__content_flex
              ]"
            >
              <span>Название: {{ educationalProject.name }}</span>
              <span>Место: {{ educationalProject.place }}</span>
              <span>Год участия: {{ educationalProject.year }}</span>
            </span>
          </div>
          <div v-else>
            <span>Не заполнено</span>
          </div>
        </div>
        <div
          v-if="changeInfo"
          :class="[
            $style.teacherDetails__content_input,
            $style.teacherDetails__content_input_border
          ]"
        >
          <p :class="$style.teacherDetails__content_text">
            Участие в образовательных проектах
          </p>
          <div
            v-for="educationalProject in educationalProjects"
            :key="educationalProject.id"
            style="display: flex; justify-content: space-between"
          >
            <div :class="$style.teacherDetails__content_input">
              <p :class="$style.teacherDetails__content_text">Название</p>
              <Input v-model="educationalProject.name" />
            </div>
            <div :class="$style.teacherDetails__content_input">
              <p :class="$style.teacherDetails__content_text">Место</p>
              <Input v-model="educationalProject.place" />
            </div>
            <div :class="$style.teacherDetails__content_input">
              <p :class="$style.teacherDetails__content_text">Год участия</p>
              <InputNumber v-model="educationalProject.year" type="container" :maxlength="4" />
            </div>
          </div>
          <Button type="table" @click="addElement('educationalProjects')">Добавить</Button>
        </div>

        <div
          v-if="!changeInfo"
          :class="[
            $style.teacherDetails__content_info,
            $style.teacherDetails__content_info_textarea
          ]"
        >
          <p>Коротко о себе*</p>
          <div>
            {{ setValueInField(teacherInfo.personalInfo) }}
          </div>
        </div>
        <div
          v-if="changeInfo"
          :class="[
            $style.teacherDetails__content_input,
            $style.teacherDetails__content_info_textarea
          ]"
          style="width: 100%"
        >
          <p>Коротко о себе*</p>
          <Input
            v-model="teacher.personalInfo"
            :class="$style.teacherDetails__input"
            style="width: 100%"
          />
        </div>
      </div>

      <div v-if="pedagogFiles.length && activeTab === 3" :class="$style.teacherDetails__files">
        <div v-for="file in pedagogFiles" :key="file.id" :class="$style.teacherDetails__file">
          <span> {{ file.name }}</span>
          <img
            src="@/assets/icons/save.svg"
            alt="download"
            @click="downloadFileFromServer(file)"
          />
          <button v-if="changeInfo" @click="setDeleteFile(file)">X</button>
        </div>
      </div>

      <div v-if="changeInfo && activeTab === 3" :class="$style.teacherDetails__content_files">
        <p>Добавление документов</p>
        <div
          v-for="type in init.documentsTypes.filter((e) => e.roleId === teacherDetails.roleId)"
          :class="[$style.teacherDetails__content_input]"
          :key="type.id"
        >
          <DocumentInput
            accept="application/pdf"
            @input="setFile"
            :type="type"
            @deleteFile="setDeleteFile"
          />
        </div>
      </div>

      <div v-if="activeTab === 4" :class="$style.teacherDetails__ratings">
        <div v-if="getOrdersRows(teacherOrders.rows).length > 0" :class="$style.teacherDetails__ratings__header">
          <div
            :class="[
              $style.teacherDetails__ratings__header__column,
              $style.border__right,
              $style.width__40
            ]"
          ></div>
          <div
            :class="[
              $style.teacherDetails__ratings__header__column,
              $style.border__right,
              $style.padding__left,
              $style.width__200
            ]"
          >
            Дата
          </div>
          <div
            :class="[
              $style.teacherDetails__ratings__header__column,
              $style.border__right,
              $style.padding__left,
              $style.width__200
            ]"
          >
            ID Заказа
          </div>
          <div
            :class="[
              $style.teacherDetails__ratings__header__column,
              $style.border__right,
              $style.padding__left,
              $style.width__37
            ]"
          >
            Родитель
          </div>
          <div
            :class="[
              $style.teacherDetails__ratings__header__column,
              $style.border__right,
              $style.padding__left,
              $style.width__200
            ]"
          >
            Оценка
          </div>
          <div
            :class="[
              $style.teacherDetails__ratings__header__column,
              $style.padding__left,
              $style.width__200
            ]"
          >
            Комментарий
          </div>
        </div>
        <div
          v-for="(teacherOrder, index) in getOrdersRows(teacherOrders.rows)"
          :key="index"
          :class="[
            $style.teacherDetails__ratings__row,
            { [$style.grey]: teacherOrder?.isHideGrade }
          ]"
        >
          <div
            :class="[
              $style.teacherDetails__ratings__row__column,
              $style.border__right,
              $style.width__40,
              $style.cursor,
              $style.center
            ]"
          >
            <img
              v-if="!teacherOrder?.isHideGrade"
              @click="changeActive(teacherOrder)"
              width="20"
              height="20"
              src="@/assets/icons/eye.svg"
              alt="icon"
            />
            <img
              v-if="teacherOrder?.isHideGrade"
              @click="changeActive(teacherOrder)"
              width="20"
              height="20"
              src="@/assets/icons/hide.svg"
              alt="icon"
            />
          </div>
          <div
            :class="[
              $style.teacherDetails__ratings__row__column,
              $style.border__right,
              $style.width__200,
              $style.padding__left,
              $style.padding__right,
              $style.space__between
            ]"
          >
            <p>{{ moment(teacherOrder.datetime).format('YYYY-MM-DD') }}</p>
            <p>{{ moment(teacherOrder.datetime).format('HH:mm') }}</p>
          </div>
          <div
            :class="[
              $style.teacherDetails__ratings__row__column,
              $style.border__right,
              $style.width__200,
              $style.padding__left,
              $style.cursor,
              $style.padding__right,
              $style.space__between
            ]"
            @click="goToOrder(teacherOrder.id)"
          >
            <p>{{ teacherOrder.id }}</p>
            <img width="20" height="20" src="@/assets/icons/arrow-grey.svg" alt="arrow" />
          </div>
          <div
            :class="[
              $style.teacherDetails__ratings__row__column,
              $style.border__right,
              $style.width__37,
              $style.padding__left
            ]"
          >
            {{ teacherOrder.customer.attributes.middleName }}
            {{ teacherOrder.customer.attributes.firstName }}
            {{ teacherOrder.customer.attributes.lastName }}
          </div>
          <div
            :class="[
              $style.teacherDetails__ratings__row__column,
              $style.border__right,
              $style.width__200,
              $style.padding__left,
              $style.padding__right,
              $style.space__between
            ]"
          >
            <p>{{ teacherOrder.grade }}</p>
            <p
              :class="[
                { [$style.red]: calculateDifference(teacherOrder.grade) < 0 },
                { [$style.green]: calculateDifference(teacherOrder.grade) > 0 }
              ]"
            >
              <img
                v-if="
                  !teacherOrder?.isHideGrade && calculateDifference(teacherOrder.grade) < 0
                "
                width="16"
                height="16"
                src="@/assets/icons/arrow-red.svg"
                alt="down"
              />
              <img
                v-if="
                  !teacherOrder?.isHideGrade && calculateDifference(teacherOrder.grade) > 0
                "
                width="16"
                height="16"
                src="@/assets/icons/arrow-green.svg"
                alt="up"
              />
              {{ !teacherOrder?.isHideGrade ? calculateDifference(teacherOrder.grade) : '' }}
            </p>
          </div>
          <div
            :class="[
              $style.teacherDetails__ratings__row__column,
              $style.width__200,
              $style.padding__left,
              $style.cursor
            ]"
          >
            <p :class="$style.text" @click="goToCommentDetails(teacherOrder)">Читать</p>
          </div>
        </div>
      </div>

      <div
        v-if="activeTab === 5"
        :class="[$style.teacherDetails__notes, $style.notes]"
      >
        <div :class="$style.notes__current">
          <p :class="$style.notes__title">
            Текущее значение
          </p>

          <custom-textarea
            ref="currentCommentTextarea"
            :class="$style.notes__comment"
            :value="currentCommentText"
            :maxlength="1024"
          />

          <div :class="$style.notes__buttons">
            <Button
              type="tertiary"
              @click="addNewComment"
            >
              Сохранить
            </Button>
            <Button
              type="red"
              @click="clearNewComment"
            >
              Очистить
            </Button>
          </div> 
        </div>

        <div :class="$style.notes__previous">
          <p :class="$style.notes__title">
            Предыдущее значение
          </p>

          <custom-table
            :columns="[
              { column: 'Дата', value: (item) => item.date || '', width: 10 },
              { column: 'Время', value: (item) => item.time || '', width: 5 },
              {
                column: 'Ответсвенный',
                value: (item) => {
                  const employe = item.employe
                  return employe ? `${employe.firstName} ${employe.lastName}` : ''
                },
                width: 15,
              },
              { column: 'Примечание', value: (item) => item.text || '', width: 20 },
            ]"
            :items="previousComment"
          />
        </div>
      </div>
    </div>
    <div :class="$style.teacherDetails__content_title">
      <h2>Добавление минут</h2>
    </div>

    <div :class="$style.teacherDetails__content">
      <div :class="$style.teacherDetails__content_wrap">
        <div :class="$style.teacherDetails__content_info">
          <p>Остаток минут</p>
          <div>
            <span>{{ teacherDetails.account.minutes }}</span>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isAdmin" :class="$style.teacherDetails__content">
      <div :class="$style.teacherDetails__content_wrap">
        <div :class="$style.teacherDetails__content_input">
          <p :class="$style.teacherDetails__content_text">Минуты</p>
          <InputNumber
            v-model="minutes"
            :maxlength="5"
            style="border-color: #e4e4e4"
            @input="(value) => setMinutes(value, 'minutes')"
          />
        </div>
        <div style="display: flex; margin: auto 0">
          <Button type="tertiary" @click="addMinutes">Добавить</Button>
        </div>
      </div>
    </div>
    <div :class="$style.teacherDetails__content_title">
      <h2>Список причин отказов</h2>
    </div>

    <custom-table
      :class="$style.teacherDetails__content_table"
      :columns="columns"
      :items="teacherDetails.distributionDeclines"
    />

    <div v-if="isAdmin" :class="$style.teacherDetails__buttons">
      <Button v-if="!changeInfo" type="table" @click="change"> Редактировать </Button>
      <Button v-if="changeInfo" type="table" @click="saveChange"> Сохранить </Button>
      <Button type="table" @click="checkIsSelfEmployed">
        Проверить статус самозанятости
      </Button>
      <Button type="table" @click="deleteTeacher"> Удалить </Button>

      <div style="text-align: center" v-show="showIsSelfEmployed">
        {{ isSelfEmployed ? 'Самозанят' : 'Не самозанят' }}
      </div>

      <Button type="table" :disabled="!!isVerified" @click="setIsVerified">
        Активировать
      </Button>
      <Button type="table" :disabled="!isVerified" @click="setIsDeactived">
        Деактивировать
      </Button>
    </div>

    <div :class="$style.teacherDetails__content_title">
      <h2>История заказов</h2>
    </div>
    <div v-if="teacherOrders.count" :class="$style.teacherDetails__content_orders">
      <p>Все заказы {{ teacherOrders.count }}</p>

      <transition-group
        :style="{
          height: teacherOrders.rows.length > 10 ? 4.25 * ordersOffset + 'rem' : 'auto',
          overflow: 'hidden'
        }"
        :class="$style.teacherDetails__content_wrap"
        name="transactions"
        tag="div"
      >
        <span
          v-for="order in teacherOrders.rows.slice(0, ordersOffset)"
          :key="order.id"
          :class="$style.teacherDetails__content_transaction"
        >
          <div
            :class="[
              $style.teacherDetails__content_info,
              $style.teacherDetails__content_info_id
            ]"
          >
            <p>ID</p>

            <span>{{ order.id }}</span>
          </div>

          <div
            :class="[
              $style.teacherDetails__content_info,
              $style.teacherDetails__content_info_type
            ]"
          >
            <p>Тип</p>

            <span>{{ orderTypes.find((t) => t.id === order.typeId).name }}</span>
          </div>

          <div
            :class="[
              $style.teacherDetails__content_info,
              $style.teacherDetails__content_info_date
            ]"
          >
            <p>Дата</p>

            <span>{{
              order.datetime
                ? moment(order.datetime).format('DD MMMM YYYY HH:mm')
                : 'Не назначена'
            }}</span>
          </div>

          <div
            :class="[
              $style.teacherDetails__content_info,
              $style.teacherDetails__content_info_status
            ]"
          >
            <p>Статус</p>

            <span>{{ order.status.name }}</span>
          </div>

          <div
            :class="[
              $style.teacherDetails__content_info,
              $style.teacherDetails__content_info_duration
            ]"
          >
            <p>Длительность занятия(факт/план)</p>

            <span>{{ getTime(0, order) }}/{{ getTime(order.duration.duration * 60) }}</span>
          </div>

          <div
            :class="[
              $style.teacherDetails__content_info,
              $style.teacherDetails__content_info_subject
            ]"
          >
            <p>Тип занятия</p>

            <span v-if="order.subject">{{ order.subject.name }}</span>
            <span v-else-if="order.group">{{
              order.group.logopedisticAttributes ? 'Логопед' : 'Подготовка к школе'
            }}</span>
          </div>

          <div
            :class="[
              $style.teacherDetails__content_info,
              $style.teacherDetails__content_info_pedagog
            ]"
          >
            <p>ID ученика</p>

            <span>{{ order?.student ? order?.student?.id : '---' }}</span>
          </div>
        </span>
      </transition-group>

      <img
        v-if="teacherOrders.rows.length > 10"
        :class="[
          $style.teacherDetails__content_info_arrow,
          { [$style.teacherDetails__content_info_arrow_active]: endOrders }
        ]"
        src="@/assets/icons/select-arrow.svg"
        alt="arrow"
        @click="showMoreOrders"
      />
    </div>
  </div>
</template>

<script>
import userApi from '@/api/users'
import dadataApi from '@/api/dadata'
import orderApi from '@/api/orders'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import { pluralize } from '@/utils/string'
import Moment from 'moment'
import DatePicker from 'vue2-datepicker'
import ru from 'vue2-datepicker/locale/ru'
import 'vue2-datepicker/index.css'

import Title from '@/basic/Title'
import Button from '@/basic/Button'
import Loading from '@/basic/Loading'
import Input from '@/basic/Input'
import Tabs from '@/basic/Tabs'
import Calendar from '@/basic/Calendar'
import CustomTextarea from '@/basic/CustomTextarea.vue'
import CustomTable from '@/basic/CustomTable'
import DefaultSelect from '@/basic/DefaultSelect.vue'
import InputNumber from '@/basic/InputNumber.vue'
import DocumentInput from '@/basic/DocumentInput.vue'
import { PeriodsList } from '@/widgets/periods'
import { LessonStatus } from '@/application/constants'

const orderTypes = [
  {
    id: 1,
    name: 'Срочный'
  },
  {
    id: 2,
    name: 'Отложенный'
  },
  {
    id: 3,
    name: 'Переодический'
  }
]
export default {
  name: 'TeacherDetails',
  components: {
    DocumentInput,
    InputNumber,
    DefaultSelect,
    Title,
    Button,
    Loading,
    DatePicker,
    Tabs,
    Calendar,
    CustomTextarea,
    CustomTable,
    Input,
    PeriodsList,
  },
  data() {
    return {
      lang: ru,
      loading: false,
      minutes: null,
      ordersOffset: 10,
      endOrders: false,
      orderTypes,
      changeInfo: false,
      choosenDate: Moment().format('YYYY-MM-DD'),
      hasAdvancedTraining: [
        {
          id: 1,
          name: 'Есть'
        },
        {
          name: 'Нет',
          id: 2
        }
      ],
      teacher: {
        id: 0,
        lastName: '',
        firstName: '',
        middleName: '',
        genderId: null,
        email: '',
        phone: '',
        comments: null,
        teachingServiceYears: null,
        scientificServiceYears: null,
        minAcceptableGrade: null,
        maxAcceptableGrade: null,
        personalInfo: '',
        currentWorkingPlace: '',
        perfectStudentsQuantity: null,
        successfulStudentsQuantity: null,
        academicDegreeId: null
      },
      types: [],
      subjects: [],
      teacherEducations: [],
      momentFormat: {
        stringify: (date) => {
          return date ? Moment(date).format('DD-MM-YYYY') : ''
        }
      },
      teacherBirthday: '',
      teacherReservedPhone: '',
      universities: [],
      isStatusShown: false,
      isVerified: false,
      publications: [],
      olympiadsWinners: [],
      conferences: [],
      educationalProjects: [],
      competitions: [],
      isSelfEmployed: false,
      showIsSelfEmployed: false,
      canSave: false,
      educations: [],
      educationChanges: null,
      editEducation: false,
      searchText: '',
      timeout: null,
      manualFilling: false,
      errors: {
        firstName: false,
        lastName: false,
        email: false,
        region: false
      },
      firstFill: true,
      regions: [],
      educationRegions: [],
      educationUniversity: [],
      locality: {
        region: '',
        latitude: null,
        longitude: null,
        timezone: null
      },
      tabs: [
        {
          id: 1,
          name: 'Личные данные'
        },
        {
          id: 2,
          name: 'Календарь'
        },
        {
          id: 3,
          name: 'Доп. информация'
        },
        {
          id: 4,
          name: 'Рейтинг'
        },
        {
          id: 5,
          name: 'Примечание'
        }
      ],
      periods: [],
      activeTab: 1,
      columns: [
        {
          column: 'ID',
          key: 'id',
          width: 5,
          value: (row) => {
            return row.id
          }
        },
        {
          column: 'Текст',
          width: 20,
          value: (row) => {
            return row.text
          }
        },
        {
          column: 'Создано в',
          width: 10,
          value: (row) => {
            return Moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss')
          }
        },
        {
          column: 'ID заказа',
          width: 10,
          value: (row) => {
            return row.orderId
          }
        },
        {
          column: 'Тип',
          width: 15,
          value: (row) => {
            return row?.type?.name
          }
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      teacherDetails: 'teacherDetails',
      pedagogFiles: 'pedagogFiles',
      isAdmin: 'isAdmin',
      teacherOrders: 'teacherOrders',
      constants: 'constants',
      init: 'init'
    }),
    currentCommentText() {
      return this.teacher.comments[0] ? this.teacher.comments[0].text : ''
    },
    previousComment() {
      return this.teacher.comments[1] ? [this.teacher.comments[1]] : []
    },
    moment() {
      return Moment
    },
    status() {
      return this.isVerified ? 'Активен' : 'Не активен'
    },

    pedagogId() {
      return this.teacherDetails?.id ?? this.$route.params.id
    },

    teacherAttributes() {
      return this.teacherDetails.attributes
    },

    teacherInfo() {
      return this.teacherDetails.teacher
    },
    lastActiveOrder() {
      const activeOrders = this.ordersRating.filter((order) => order.active)

      if (activeOrders.length === 0) {
        return 0
      }
      const totalRating = activeOrders.reduce(
        (sum, order) => sum + parseFloat(order.rating),
        0
      )
      return (totalRating / activeOrders.length).toFixed(2)
    }
  },

  watch: {
    searchText() {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        if (
          this.searchText.length > 2 &&
          this.searchText !== this.educationChanges?.name &&
          !this.manualFilling
        ) {
          this.searchUniversity()
        } else if (this.manualFilling && this.searchText.length > 2) {
          this.educationChanges = { name: this.searchText, educationId: this.editEducation }
        }
      }, 500)
    }
  },

  async created() {
    await this.getTeacherProfile()
  },

  methods: {
    ...mapActions([
      'getTeacher',
      'getPedagogFiles',
      'getTeacherOrders',
      'getConstants',
      'getInit'
    ]),
    ...mapMutations(['setTeacherDetails']),

    async getTeacherProfile() {
      try {
        this.loading = true
        await Promise.all([
          this.getTeacher(this.pedagogId),
          this.getPedagogFiles(this.pedagogId)
        ])
        if (this.teacherDetails?.teacher?.workingPeriod) {
          this.periods = this.teacherDetails.teacher.workingPeriod
        }

        if (this.teacherDetails?.id) {
          await this.getTeacherOrders({
            filter: {
              teacherId: this.teacherDetails.id,
              statusId: [
                LessonStatus.NEW,
                LessonStatus.APPOINTED,
                LessonStatus.ACTIVE,
                LessonStatus.CLOSED
              ]
            }
          })
        }
        if (this.teacherAttributes?.region) {
          this.locality.region = this.teacherAttributes.region
          this.locality.latitude = this.teacherAttributes.latitude
          this.locality.longitude = this.teacherAttributes.longitude
          this.locality.timezone = this.teacherAttributes.timezone
        }

        if (this.teacherAttributes?.birthday) {
          this.teacherBirthday = Moment(this.teacherAttributes.birthday).toDate()
        }
        if (this.teacherDetails.teacher?.educations.length > 0) {
          this.teacherEducations = this.teacherDetails.teacher.educations.map((obj) => ({
            ...obj,
            year: String(obj.year),
            hasAdvancedTraining: obj.hasAdvancedTraining === true ? 1 : 2
        }))
        } else {
          this.teacherEducations = [
            {
              year: '',
              university: '',
              place: '',
              speciality: '',
              hasAdvancedTraining: null,
              manualFiling: false
            }
          ]
        }
        if (this.teacherAttributes) {
          this.teacher.genderId = this.teacherAttributes?.gender?.id || null
          this.teacher.lastName = this.teacherAttributes.lastName
          this.teacher.firstName = this.teacherAttributes.firstName
          this.teacher.middleName = this.teacherAttributes.middleName
          this.teacher.email = this.teacherDetails.email
          this.teacher.phone = String(this.teacherDetails.phone)
        }

        Object.assign(this.teacher, {
          id: this.teacherInfo.id || 0,
          teachingServiceYears: String(this.teacherInfo.teachingServiceYears),
          scientificServiceYears: String(this.teacherInfo.scientificServiceYears),
          minAcceptableGrade: String(this.teacherInfo.minAcceptableGrade),
          maxAcceptableGrade: String(this.teacherInfo.maxAcceptableGrade),
          comments: this.teacherInfo.comments || null,
          personalInfo: this.teacherInfo.personalInfo,
          academicDegreeId: this.teacherInfo?.academicDegree?.academicDegree?.id || null,
          currentWorkingPlace: this.teacherInfo.currentWorkingPlace,
          successfulStudentsQuantity: String(this.teacherInfo.successfulStudentsQuantity),
          perfectStudentsQuantity: String(this.teacherInfo.perfectStudentsQuantity)
        })

        this.publications = this.teacherInfo.publications.map((obj) => ({
          ...obj,
          year: String(obj.year)
        }))
        this.olympiadsWinners = this.teacherInfo.olympiadsWinners.map((obj) => ({
          ...obj,
          place: String(obj.place)
        }))
        this.conferences = this.teacherInfo.conferences.map((obj) => ({
          ...obj,
          year: String(obj.year),
          place: String(obj.place)
        }))
        this.educationalProjects = this.teacherInfo.educationalProjects.map((obj) => ({
          ...obj,
          year: String(obj.year),
          place: String(obj.place)
        }))
        this.competitions = this.teacherInfo.competitions
        this.types = this.teacherInfo.lessonsTypes.map((e) => e.lessonType)
        this.subjects = this.teacherInfo.subjects.map((e) => e.subject)
      } finally {
        this.loading = false
      }

      this.isVerified = this.teacherDetails.teacher.isVerified
    },
    async addNewComment() {
      try {
        const data = {
          text: this.$refs.currentCommentTextarea.$el.value,
          teacherId: this.teacher.id
        }
        const { status, } = await userApi.addComment(data)

        if (status === 200) {
          await this.getTeacherProfile()
        }
      } catch (error) {
        await this.$store.dispatch('openModal', [
          'Alert',
          {
            title: 'Ошибка',
            message: 'Ошибка добавление комментария преподавателю!'
          }
        ])
      }
    },
    async clearNewComment() {
      this.$refs.currentCommentTextarea.$el.value = ''
      await this.addNewComment()
    },

    setChoosenDate(date) {
      this.choosenDate = date

    },
    setValueInField(value) {
      return value ? value : 'Не заполнено'
    },
    goToOrder(id) {
      this.$router.push(`/orders/${id}`)
    },
    async changeActive(obj) {
      await orderApi.hideRating({
        orderId: obj.id,
        isHideGrade: !obj.isHideGrade
      })
      const user = await this.getTeacher(this.teacherDetails.id)
      await this.getTeacherOrders({
          filter: {
            teacherId: this.teacherDetails.id,
            statusId: [
              LessonStatus.NEW,
              LessonStatus.APPOINTED,
              LessonStatus.ACTIVE,
              LessonStatus.CLOSED
            ]
          }
        })
      this.setTeacherDetails({ ...user })
    },
    calculateDifference(rating) {
      return (rating - this.teacherInfo?.rating?.rating).toFixed(2) != 0 ? (rating - this.teacherInfo?.rating?.rating).toFixed(2) : ''
    },
    goToCommentDetails(comment) {
      this.$store.dispatch('openModal', ['CommentModal', { comment: comment }])
    },
    setText(text) {
      this.errors[text] = this.teacher[text]?.length < 2
    },
    addEducation() {
      this.teacherEducations.push({
        year: '',
        university: '',
        place: '',
        speciality: '',
        hasAdvancedTraining: null,
        manualFiling: false
      })
    },
    deleteEducation() {
      this.teacherEducations.pop()
    },
    showMoreOrders() {
      if (this.teacherOrders.rows.length > this.ordersOffset) {
        const num = this.teacherOrders.rows.length - this.ordersOffset
        this.ordersOffset += num > 10 ? 10 : num
        if (this.teacherOrders.rows.length <= this.ordersOffset && !this.endOrders) {
          this.endOrders = true
        }
      } else {
        this.ordersOffset = 10
        this.endOrders = false
      }
    },
    setMinutes(value, type) {
      if (value.length === 0) {
        this[type] = null
      }
    },
    getOrdersRows(rows){
      return rows.filter(e => e.status.id === LessonStatus.CLOSED && e.grade)
    },
    async addMinutes() {
      try {
        this.loading = true
        const data = { userId: this.teacherInfo.userId }
        if (this.minutes === '0') return
        if (this.minutes) {
          data.minutes = +this.minutes
        }
        const { success } = await userApi.addMinutes(data)
        if (success) {
          this.minutes = null
          const user = await this.getTeacher(this.teacherDetails.id)
          this.setTeacherDetails({ ...user })
        }
      } catch (error) {
        console.warn(error)
        await this.$store.dispatch('openModal', [
          'Alert',
          {
            title: 'Ошибка',
            message: 'Ошибка добавление минут преподавателю на сервере!'
          }
        ])
      } finally {
        this.loading = false
      }
    },
    getTime(seconds = 0, order) {
      if (!seconds && order?.chat?.end) {
        seconds = Moment(order?.chat?.end).diff(Moment(order?.chat?.start), 's')
      }
      return Moment.utc(seconds * 1000).format('HH:mm:ss')
    },
    async getRegions(query) {
      this.errors.region = true
      if (query.length >= 2) {
        try {
          const { data } = await dadataApi.getAddressSuggestions(query, 5)

          if (data?.success) {
            this.regions = data.result
          }
        } catch (error) {
          console.warn(error)
          await this.$store.dispatch('openModal', [
            'Alert',
            {
              title: 'Ошибка',
              message: 'Ошибка получения предложения адресов с сервера!'
            }
          ])
        }
      } else {
        this.regions = []
      }
    },
    async getEducationUniversity(query) {
      if (query.length >= 2) {
        try {
          const data = await dadataApi.getUniversitySuggestions({
            query: query,
            count: 5,
            userId: this.teacherDetails.id
          })
          if (data?.success) {
            this.educationUniversity = data.result
          }
        } catch (error) {
          console.warn(error)
          await this.$store.dispatch('openModal', [
            'Alert',
            {
              title: 'Ошибка',
              message: 'Ошибка получения предложения адресов с сервера!'
            }
          ])
        }
      } else {
        this.educationUniversity = []
      }
    },
    async getEducationRegions(query) {
      if (query.length >= 2) {
        try {
          const { data } = await dadataApi.getAddressSuggestions(query, 5)

          if (data?.success) {
            this.educationRegions = data.result
          }
        } catch (error) {
          console.warn(error)
          await this.$store.dispatch('openModal', [
            'Alert',
            {
              title: 'Ошибка',
              message: 'Ошибка получения предложения адресов с сервера!'
            }
          ])
        }
      } else {
        this.educationRegions = []
      }
    },
    async searchUniversity() {
      this.educations = (
        await dadataApi.getUniversitySuggestions({
          query: this.searchText,
          count: 5,
          userId: this.teacherDetails.id
        })
      ).result
    },

    resetRegions() {
      this.regions = []
    },
    resetEducationRegions() {
      this.educationRegions = []
    },
    resetEducationUniversity() {
      this.educationUniversity = []
    },
    // setPhone(phone) {
    //   this.errors.phone = ![0, 11].includes(phone?.replace(/\D/g, '').length)
    // },
    setEmail() {
      this.errors.email = !/.+@.+\.[A-Za-z]+$/.test(this.teacher.email)
    },
    async setRegion(region) {
      this.regions = []

      try {
        const { data } = await dadataApi.getAddressSuggestions(region, 1)

        if (data?.success && data?.result[0]?.timezone) {
          this.locality = {
            region,
            latitude: data.result[0].latitude ?? 0,
            longitude: data.result[0].longitude ?? 0,
            timezone: +/\d+/.exec(data.result[0].timezone)
          }
          this.errors.region = false
        } else {
          await this.$store.dispatch('openModal', [
            'Alert',
            {
              title: 'Ошибка',
              message:
                'По данному населённому пункту нет дополнительной информации, пожалуйста, выберите ближайший крупный город.'
            }
          ])
        }
      } catch (error) {
        console.warn(error)
        await this.$store.dispatch('openModal', [
          'Alert',
          {
            title: 'Ошибка',
            message: 'Ошибка получения информации по адресу с сервера!'
          }
        ])
      }
    },
    async setEducationUniversity(region, id) {
      this.educationUniversity = []
      try {
        const data = await dadataApi.getUniversitySuggestions({
          query: region,
          count: 1,
          userId: this.teacherDetails.id
        })
        if (data?.success) {
          this.teacherEducations[id].university = region
        } else {
          await this.$store.dispatch('openModal', [
            'Alert',
            {
              title: 'Ошибка',
              message:
                'По данному населённому пункту нет дополнительной информации, пожалуйста, выберите ближайший крупный город.'
            }
          ])
        }
      } catch (error) {
        console.warn(error)
        await this.$store.dispatch('openModal', [
          'Alert',
          {
            title: 'Ошибка',
            message: 'Ошибка получения информации по адресу с сервера!'
          }
        ])
      }
    },
    async setEducationRegion(region, id) {
      this.educationRegions = []
      try {
        const { data } = await dadataApi.getAddressSuggestions(region, 1)

        if (data?.success && data?.result[0]?.timezone) {
          this.teacherEducations[id].place = region
        } else {
          await this.$store.dispatch('openModal', [
            'Alert',
            {
              title: 'Ошибка',
              message:
                'По данному населённому пункту нет дополнительной информации, пожалуйста, выберите ближайший крупный город.'
            }
          ])
        }
      } catch (error) {
        console.warn(error)
        await this.$store.dispatch('openModal', [
          'Alert',
          {
            title: 'Ошибка',
            message: 'Ошибка получения информации по адресу с сервера!'
          }
        ])
      }
    },
    addElement(element) {
      const newElement = {
        conferences: { place: '', year: '', name: '' },
        educationalProjects: { place: '', year: '', name: '' },
        olympiadsWinners: { place: '', text: '', typeId: null },
        competitions: { text: '', documentId: null, typeId: null },
        publications: { year: '', heading: '', link: '', name: '' }
      }[element]
      this[element].push(newElement)
    },
    selectEducation({ address, name, educationId }) {
      this.educationChanges = { address, name, educationId }
      this.searchText = name
      this.educations = []
    },

    editOneEducation(value) {
      this.searchText = ''
      this.educations = []
      this.educationChanges = null
      this.editEducation = value
    },

    async saveEducation() {
      if (!this.educationChanges) return

      const routes = {
        3: 'teachers',
        4: 'logopedists',
        5: 'preschoolTeachers'
      }

      await userApi.updateEducation({
        route: routes[this.teacherDetails.roleId],
        id: this.educationChanges.educationId,
        fields: {
          university: this.educationChanges.name,
          manualFilling: this.manualFilling
        }
      })

      window.location.reload()
    },

    setPluralize(value) {
      return `${value} ${pluralize(value, 'год', 'года', 'лет')}`
    },
    change() {
      this.changeInfo = !this.changeInfo
    },
    hasValidateErorr() {
      for (const key in this.errors) {
        if (this.errors[key]) {
          return true
        }
      }
    },
    async onTeacherUpdate() {
      this.loading = true
      try {
        const data = await userApi.updateTeacher({
          minAcceptableGrade: this.teacher.minAcceptableGrade,
          maxAcceptableGrade: this.teacher.maxAcceptableGrade,
          successfulStudentsQuantity: this.teacher.successfulStudentsQuantity,
          perfectStudentsQuantity: this.teacher.perfectStudentsQuantity,
          personalInfo: this.teacher.personalInfo,
          scientificServiceYears: this.teacher.scientificServiceYears,
          teachingServiceYears: this.teacher.teachingServiceYears,
          currentWorkingPlace: this.teacher.currentWorkingPlace,
          id: this.teacherDetails.id,
          lessonsTypes: this.types.map((type) => {
            return { typeId: type.id }
          }),
          publications: this.publications,
          olympiadsWinners: this.olympiadsWinners,
          educations: this.teacherEducations.map((e) => {
            return {
              ...e,
              hasAdvancedTraining: e.hasAdvancedTraining === 1 ? 1 : 0
            }
          }),
          conferences: this.conferences,
          subjects: this.subjects.map((subject) => {
            return { subjectId: subject.id }
          }),
          educationalProjects: this.educationalProjects,
          competitions: this.competitions
        })
        if (data?.success) {
          this.loading = false
        }
      } catch (e) {
        console.warn(e)
        await this.$store.dispatch('openModal', [
          'Alert',
          {
            title: 'Ошибка',
            message: 'Ошибка обновления информации преподавателя на сервере!'
          }
        ])
      } finally {
        this.loading = false
      }
    },
    async showModal(errorMessage) {
      await this.$store.dispatch('openModal', [
        'Alert',
        { title: 'Ошибка!', message: errorMessage }
      ])
    },
    async saveChange() {
      if (
        !this.teacher.personalInfo ||
        this.teacher.personalInfo.length < 50 ||
        this.teacher.personalInfo.length > 300
      ) {
        this.showModal('Поле "Коротко о себе" должно содержать от 50 до 300 символов')
        return
      }
      if (!this.teacher.firstName || this.teacher.firstName.length < 2) {
        this.showModal('Поле "Имя" должно иметь длину слова больше 2 символов')
        this.errors.firstName = true
        return
      } else {
        this.errors.firstName = false
      }
      if (!this.teacher.lastName || this.teacher.lastName.length < 2) {
        this.errors.lastName = true
        this.showModal('Поле "Фамилия" должно иметь длину слова больше 2 символов')
        return
      } else {
        this.errors.lastName = false
      }
      if (!this.teacher.email || this.teacher.email.length < 2) {
        this.errors.email = true
        this.showModal('Поле "Почта" должно иметь длину слова больше 2 символов')
        return
      } else {
        this.errors.email = false
      }
      if (!this.locality.region || this.locality.region.length < 2) {
        this.errors.region = true
        this.showModal('Не заполнено поле "Регион", оно должно быть длинее 2 символов')
        return
      } else {
        this.errors.region = false
      }
      if (
        !this.teacher.successfulStudentsQuantity ||
        this.teacher.successfulStudentsQuantity.length < 1
      ) {
        this.teacher.successfulStudentsQuantity = null
      }
      if (
        !this.teacher.scientificServiceYears ||
        this.teacher.scientificServiceYears.length < 1
      ) {
        this.showModal('Не заполнено поле "Стаж научной деятельности"')
        return
      }
      if (!this.teacher.teachingServiceYears || this.teacher.teachingServiceYears.length < 1) {
        this.showModal('Не заполнено поле "Преподавательский стаж"')
        return
      }
      if (
        !this.teacher.perfectStudentsQuantity ||
        this.teacher.perfectStudentsQuantity.length < 1
      ) {
        this.teacher.perfectStudentsQuantity = null
      }
      if (this.subjects.length < 1) {
        this.showModal('Выберите хотя бы один Предмет')
        return
      }
      if (this.types.length < 1) {
        this.showModal('Выберите хотя бы один Тип занятия')
        return
      }
      if (!this.teacher.minAcceptableGrade || this.teacher.minAcceptableGrade.length < 1) {
        this.showModal('Выберите минимальный класс ученика')
        return
      }
      if (!this.teacher.maxAcceptableGrade || this.teacher.maxAcceptableGrade.length < 1) {
        this.showModal('Выберите максимальный класс ученика')
        return
      }
      for (let teacherEducation in this.teacherEducations) {
        const obj = this.teacherEducations[teacherEducation]
        if (
          !obj.year ||
          !obj.university ||
          !obj.place ||
          !obj.speciality ||
          !obj.hasAdvancedTraining
        ) {
          let errorMessage = ''
          if (!obj.university) {
            errorMessage = 'Не заполнено поле "Вуз"'
          } else if (!obj.place) {
            errorMessage = 'Не заполнено поле "Город"'
          } else if (!obj.speciality) {
            errorMessage = 'Не заполнено поле "Специальность"'
          } else if (!obj.hasAdvancedTraining) {
            errorMessage = 'Не заполнено поле "Педагогическое образование"'
          } else if (!obj.year) {
            errorMessage = 'Не заполнено поле "Год окончания"'
          }
          this.showModal(errorMessage)
          return
        }
      }
      for (let olympiadsWinner in this.olympiadsWinners) {
        const obj = this.olympiadsWinners[olympiadsWinner]
        if (!obj.place || !obj.text || !obj.typeId) {
          let errorMessage = ''
          if (!obj.place) {
            errorMessage = 'В блоке "Олимпиадники" не заполнено поле "Место"'
          } else if (!obj.text) {
            errorMessage = 'В блоке "Олимпиадники" не заполнено поле "Название олимпиады"'
          } else if (!obj.typeId) {
            errorMessage = 'В блоке "Олимпиадники" не заполнено поле "Этап олимпиады"'
          }
          this.showModal(errorMessage)
          return
        }
      }
      for (let competition in this.competitions) {
        const obj = this.competitions[competition]
        if (!obj.text || !obj.typeId) {
          let errorMessage = ''
          if (!obj.text) {
            errorMessage =
              'В блоке "Участие в конкурсах" не заполнено поле "Название конкурса"'
          } else if (!obj.typeId) {
            errorMessage = 'В блоке "Участие в конкурсах" не заполнено поле "Тип конкурса"'
          }
          this.showModal(errorMessage)
          return
        }
      }
      for (let publication in this.publications) {
        const obj = this.publications[publication]
        if (!obj.heading || !obj.name || !obj.link || !obj.year) {
          let errorMessage = ''
          if (!obj.heading) {
            errorMessage = 'В блоке "Публикации и статьи" не заполнено поле "Заголовок"'
          } else if (!obj.name) {
            errorMessage = 'В блоке "Публикации и статьи" не заполнено поле "Название"'
          } else if (!obj.link) {
            errorMessage = 'В блоке "Публикации и статьи" не заполнено поле "Ссылка"'
          } else if (!obj.year) {
            errorMessage = 'В блоке "Публикации и статьи" не заполнено поле "Год участия"'
          }
          this.showModal(errorMessage)
          return
        }
      }
      for (let conference in this.conferences) {
        const obj = this.conferences[conference]
        if (!obj.name || !obj.place || !obj.year) {
          let errorMessage = ''
          if (!obj.name) {
            errorMessage = 'В блоке "Участие в конференциях" не заполнено поле "Название"'
          } else if (!obj.place) {
            errorMessage = 'В блоке "Участие в конференциях" не заполнено поле "Место"'
          } else if (!obj.year) {
            errorMessage = 'В блоке "Участие в конференциях" не заполнено поле "Год участия"'
          }
          this.showModal(errorMessage)
          return
        }
      }
      for (let educationalProject in this.educationalProjects) {
        const obj = this.educationalProjects[educationalProject]
        if (!obj.name || !obj.place || !obj.year) {
          let errorMessage = ''
          if (!obj.name) {
            errorMessage = 'В блоке "Участие в конференциях" не заполнено поле "Название"'
          } else if (!obj.place) {
            errorMessage = 'В блоке "Участие в конференциях" не заполнено поле "Место"'
          } else if (!obj.year) {
            errorMessage = 'В блоке "Участие в конференциях" не заполнено поле "Год участия"'
          }
          this.showModal(errorMessage)
          return
        }
      }
      try {
        await this.onTeacherUpdate()
        await this.saveUser()
        this.changeInfo = !this.changeInfo
        const user = await this.getTeacher(this.teacherDetails.id)
        this.setTeacherDetails({ ...user })
      } catch (error) {
        console.error('Ошибка при обновлении учителя:', error)
      }
    },
    async saveUser() {
      if (this.hasValidateErorr()) {
        await this.$store.dispatch('openModal', [
          'Alert',
          { title: 'Ошибка!', message: 'Заполните все обязательные поля!' }
        ])
        this.firstFill = false
        return
      }

      try {
        this.loading = true
        const { data } = await userApi.updateUser(this.teacherDetails.id, {
          attributes: {
            firstName: this.teacher.firstName,
            middleName: this.teacher.middleName,
            lastName: this.teacher.lastName,
            birthday: this.teacherBirthday
              ? Moment(this.teacherBirthday).format('YYYY-MM-DD')
              : null,
            genderId: this.teacher.genderId,
            ...this.locality
          },
          email: this.teacher.email,
          reservedPhone: this.teacherReservedPhone?.replace(/\D/g, '') || null
        })
        if (data?.success && data?.result?.lastUpdate) {
          const user = await this.getTeacher(this.teacherDetails.id)
          this.setTeacherDetails({ ...user, lastUpdate: data.result.lastUpdate })
          this.loading = false
        }
      } catch (e) {
        console.warn(e)
        await this.$store.dispatch('openModal', [
          'Alert',
          {
            title: 'Ошибка',
            message: 'Ошибка обновления информации преподавателя на сервере!'
          }
        ])
      } finally {
        this.loading = false
      }
    },
    async checkIsSelfEmployed() {
      try {
        const { data } = await userApi.checkIsSelfEmployed(this.pedagogId)

        if (data?.success) {
          this.isSelfEmployed = data.result.active

          this.toggleShowIsSelfEmployed()
        }
      } catch (error) {
        console.warn(error)
      }
    },
    async deleteTeacher() {
      try {
        this.loading = true
        const { success } = await userApi.deleteUser({ userId: this.teacherInfo.userId })
        if (success) {
          await this.$router.push('/teachers')
        } else {
          await this.$store.dispatch('openModal', [
            'Alert',
            {
              title: 'Ошибка',
              message: success
            }
          ])
        }
      } catch (error) {
        console.warn(error)
        await this.$store.dispatch('openModal', [
          'Alert',
          {
            title: 'Ошибка',
            message: 'Ошибка на сервере при удалении преподавателя!'
          }
        ])
      } finally {
        this.loading = false
      }
    },
    toggleShowIsSelfEmployed() {
      this.showIsSelfEmployed = !this.showIsSelfEmployed
    },

    async setIsVerified() {
      try {
        const { data } = await userApi.setIsVerified(this.pedagogId)

        if (data?.success) {
          this.isVerified = true
        }
      } catch (error) {
        console.warn(error)
      }
    },
    async setIsDeactived() {
      try {
        const { data } = await userApi.setIsDeactived(this.pedagogId)
        if (data?.success) {
          this.isVerified = false
        }
      } catch (error) {
        console.warn(error)
      }
    },
    async setFile(file, typeId) {
      this.loading = true
      const formData = new FormData()
      const userId = this.teacherDetails.id
      formData.append('file', file)
      formData.append('userId', userId)
      const resp = await userApi.setFile(formData, typeId)
      if (resp?.status === 200) {
        const user = await this.getTeacher(this.teacherDetails.id)
        this.setTeacherDetails({ ...user })
        this.getPedagogFiles(this.teacherDetails.id)
        this.loading = false
      }
    },
    async setDeleteFile(file) {
      this.loading = true
      try {
        const resp = await userApi.deleteFile(file.id)
        if (resp?.status === 200) {
          const user = await this.getTeacher(this.teacherDetails.id)
          this.setTeacherDetails({ ...user })
          this.getPedagogFiles(this.teacherDetails.id)
          this.loading = false
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async downloadFileFromServer(data) {
      try {
        this.loading = true

        const resp = await userApi.downloadPedagogFile(data.id)

        if (resp?.status === 200) {
          let a = document.createElement('a')
          let file = new Blob([resp.data], { type: 'application/json' })
          a.href = URL.createObjectURL(file)
          a.download = data.name
          a.click()
        }
      } catch (error) {
        console.warn(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect {
  width: 18.75rem !important;
}
</style>
<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.title {
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  margin-right: 2.5rem;
  align-items: center;
  width: 3.75rem;
  justify-content: space-between;
}
.grey {
  color: grey;
}
.text {
  text-decoration: underline;
  font-weight: bold;
}
.center {
  justify-content: center;
}
.border__right {
  border-right: 1px solid grey;
}
.padding__left {
  padding-left: 10px;
}
.padding__right {
  padding-right: 20px;
}
.space__between {
  justify-content: space-between;
}
.width {
  &__40 {
    width: 40px;
  }
  &__37 {
    width: 37%;
  }
  &__200 {
    width: 200px;
  }
}
.green {
  color: green;
}
.red {
  color: red;
}
.cursor {
  cursor: pointer;
}
.border__education {
  border: 0.063rem solid grey;
  border-radius: 0.625rem;
  padding: 0.313rem;
  width: 100%;
  margin-bottom: 1rem;
}

.teacherDetails {
  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 90vh;
  }
  &__ratings {
    margin-bottom: 20px;
    &__header {
      display: flex;
      border: 1px solid grey;
      align-items: center;
      text-align: left;
      font-weight: bold;
      &__column {
        height: 40px;
        align-items: center;
        display: flex;
      }
    }
    &__row {
      display: flex;
      border: 1px solid grey;
      align-items: center;
      border-top: none;
      text-align: left;
      &__column {
        align-items: center;
        height: 40px;
        display: flex;
      }
    }
  }
  &__notes {
    margin-bottom: 20px;
  }
  &__content {
    &_title {
      margin-bottom: 1rem;

      h2 {
        font-size: 1.25rem;
        font-weight: 500;
      }
    }

    &_wrap {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      transition: height 0.3s ease;
    }

    &_education {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin: 0 0 1rem;
    }

    &_info {
      position: relative;
      min-width: 17.188rem;
      width: auto;
      margin-right: 0.625rem;
      margin-bottom: 0.938rem;

      &_educations {
        max-width: 17.188rem;
        font-size: 0.9rem;
        overflow: hidden;
        position: relative;
        z-index: 99;
        left: 0;
        right: 0;
        background: #ffffff;
        border: 1px solid #e4e4e4;
        color: #454545;
        div {
          cursor: pointer;
          padding: 0.5rem;
          border-bottom: 1px solid #e4e4e4;
          &:last-child {
            border-bottom: none;
          }
          &:hover {
            background-color: lighten($gray, 40%);
          }
        }
      }

      p {
        position: absolute;
        top: -0.5rem;
        left: 0.313rem;
        background: $white;
        font-size: 0.75rem;
        color: $gray;
      }

      div {
        border: 1px solid $medium-gray;
        padding: 0.375rem;
        input[type='checkbox'] {
          display: inline-block !important;
        }
        img {
          position: absolute;
          height: 1rem;
          cursor: pointer;
          &:nth-child(2) {
            right: 2.5rem;
          }
          &:nth-child(3) {
            right: 1rem;
          }
          &:nth-child(4) {
            right: 1rem;
          }
        }
      }

      &_textarea {
        div {
          max-height: 18.75rem;
          max-width: 25rem;
          min-width: 17.188rem;
          overflow-wrap: break-word;
        }
      }

      &_status {
        min-width: 5rem;
      }

      &_duration {
        min-width: 12rem;
      }

      &_type {
        min-width: 5rem;
      }

      &_date {
        min-width: 9rem;
      }

      &_id {
        display: flex;
        justify-content: center;
        min-width: 3rem;
      }

      &_arrow {
        position: absolute;
        bottom: -1.5rem;
        left: 50%;
        right: 50%;
        padding: 0.5rem;
        cursor: pointer;
        transition: all 0.3s ease;

        &_active {
          transform: rotate(180deg);
        }
      }
    }

    &_item {
      display: block;
      padding: 0.375rem;
      border-bottom: 1px solid $medium-gray;

      span {
        padding: 0.125rem 0;
      }

      &:last-child {
        border: none;
      }
    }

    &_flex {
      display: flex;
      flex-direction: column;
    }

    &_table {
      margin-bottom: 1rem;
    }

    &_orders {
      position: relative;
      margin: 0 0 2rem;

      p {
        position: absolute;
        top: -0.5rem;
        left: 0;
        z-index: 5;
        margin: 0 0 0.15rem 0.35rem;
        background: $white;
        font-size: 0.7rem;
        color: $gray;
      }

      div {
        position: relative;
        border: 1px solid $input-border;
        border-radius: 0.25rem;
        padding: 0.5rem 0.75rem;
        font-size: 14px;
      }

      @media only screen and (max-width: 1473px) {
        span {
          font-size: 0.65rem;
        }
      }
    }

    &_transaction {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0.938rem 0 0;
      border-bottom: 1px solid $medium-gray;

      &:last-child {
        border: none;
      }

      p {
        position: absolute;
        top: -0.5rem;
        left: 0;
        z-index: 5;
      }
    }

    &_input {
      margin: 0 0.625rem 0.938rem 0;
      &_border {
        border: 1px solid #c4c4c4;
      }
    }

    &_text {
      margin: 0 0 0.15rem 0.35rem;
      font-size: 0.7rem;
      color: $gray;
    }
  }
  &__calendar {
    display: flex;
    gap: 64px;
    height: 625px;
    overflow: auto;
    & > div {
      flex-shrink: 0;
      height: max-content;
    }
  }
  &__files {
    width: 70%;
    border: 1px solid $medium-gray;
    padding: 0.375rem;
    margin: 0 0 1rem;
  }

  &__file {
    display: flex;
    justify-content: space-between;
    padding: 0.375rem;
    border-bottom: 1px solid $medium-gray;

    &:last-child {
      border: none;
    }

    img {
      cursor: pointer;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    button {
      max-width: 18.75rem;
      padding: 0.625rem !important;
    }

    button:first-child {
      margin-bottom: 0.625rem;
    }
    button:nth-child(2) {
      margin-bottom: 0.625rem;
    }
    button:nth-child(3) {
      margin-bottom: 0.625rem;
    }
    button:nth-child(4) {
      margin-bottom: 0.625rem;
    }
    button:nth-child(5) {
      margin-bottom: 0.625rem;
    }
  }

  &__checkbox {
    position: absolute;
    right: 4rem;
    width: 1rem;
    height: 1rem;
    appearance: auto !important;
  }

  &__wrapper {
    position: relative;
    width: 25rem;
  }

  &__address {
    width: 100%;
  }

  &__dropdown {
    overflow: hidden;
    position: absolute;
    z-index: 99;
    background: $white;
    border: 1px solid $platinum;
    color: $wet-asphalt;
    width: 12.375rem;
  }

  &__region {
    padding: 0.5rem 0.625rem;
    border-bottom: 1px solid $gainsborough;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $green-tea;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__close {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 80;
  }
}

.notes {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  &__current,
  &__previous {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  &__current {
    width: 40rem;
  }
  &__title {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 700;
  }
  &__comment {
    width: 100%;
    min-height: 6.25rem;
  }
  &__buttons {
    display: flex;
    gap: 1.5rem;
    & > button {
      width: max-content;
    }
  }
}

.periods {
  width: 25.1875rem;
  background-color: #f8f8f8;
  border-radius: 0.625rem;
  padding: 1.25rem;
  padding-bottom: 3.375rem;
  position: relative;

  .pin {
    width: 1.5rem;
    height: 3.4375rem;
    position: absolute;
    right: 1.75rem;
    top: -1.4375rem;
  }
  .calendar {
    margin-top: 3.25rem;
  }
}
</style>
