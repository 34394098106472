import { render, staticRenderFns } from "./TeacherDetails.vue?vue&type=template&id=e27006c6"
import script from "./TeacherDetails.vue?vue&type=script&lang=js"
export * from "./TeacherDetails.vue?vue&type=script&lang=js"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./TeacherDetails.vue?vue&type=style&index=1&id=e27006c6&prod&lang=css"
import style2 from "./TeacherDetails.vue?vue&type=style&index=2&id=e27006c6&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style2.locals || style2)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports